const API_PREFIX = '';

let __access_token = null, __refresh_token = null;

function ApiError(response, message, data) {
    this.name = 'ApiError';
    this.message = message || 'api error';
    this.response = response;
    //this.stack = (new Error()).stack;
    this.data = data;
  }
ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;
  

async function api(url, options = {}) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;

    const reqOptions = {
        method,
        headers: {
            //...DEFAULT_HEADERS,
            ...(__access_token && { Authorization: `bearer ${__access_token}` }),
            ...headers,
        },
        credentials: 'same-origin',
        ...extraOpts,
    };

    if (body) {
        if (body instanceof FormData) {
            reqOptions.body = body;
        } else {
            reqOptions.body = JSON.stringify(body);
            reqOptions.headers = {...reqOptions.headers, "Content-Type": "application/json"};
        }
    }

    let queryString = '';
    if (query) {
        // Convert to encoded string and prepend with ?
        queryString = new URLSearchParams(query).toString();
        queryString = queryString && `?${queryString}`;
    }

    const URL = `${API_PREFIX}${url}${queryString}`;

    try {
        const response = await fetch(URL, reqOptions);
        if (response.ok) {
            return response.json();
        } else {
            const data = await response.json();
            throw new ApiError(response, 'api error', data);
        }
    } catch(error) {
        throw error;
    }
}

export async function GET(url, options = {}) {
    return api(url, {...options, method: 'GET'});
}

export async function POST(url, data, options = {}) {
    return api(url, {...options, method: 'POST', body: data});
}

export async function PUT(url, data, options = {}) {
    return api(url, {...options, method: 'PUT', body: data});
}

export async function DELETE(url, data, options = {}) {
    return api(url, {...options, method: 'DELETE', body: data});
}

