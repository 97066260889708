import {GET} from './lib/api';

document.addEventListener('DOMContentLoaded', () => {
    const jackpot = document.getElementById('jackpot');
    const stepNum = document.getElementById('step-num');
    const winnersBody = document.getElementById('winners');
    (async function reload() {
        let r;
        try {
            r = await GET('/state');
        } catch(err) {

        }

        // set info
        jackpot.innerHTML = r?.jackpot;
        stepNum.innerHTML = r?.step;
        
        // activate numbers
        const buttons = document.querySelectorAll('a.num-btn');
        buttons.forEach(b => {
            if ((r.numbers||[]).includes(parseInt(b.dataset.num))) {
                b.classList.add('active');
            } else {
                b.classList.remove('active');
            }
        });

        winnersBody.innerHTML = r?.winners;
        
        const timeout = setTimeout(reload, 1000);
    })();
});